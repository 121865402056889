import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys, columns } from "./config/index";
import { UserequipmentApi, BlacklistApi } from "@/api";
import cloneDeep from "lodash/cloneDeep";
import { OTHER, BATCH_UNPASS } from "@/utils/buttons";
export default {
  name: "userequipment",
  components: {},
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      columns: columns,
      searchKeys: searchKeys,
      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      source: {},
      // 表单编辑需要的字段

      defaultIndex: 0,
      types: [{
        id: 1,
        name: "多账号归属同设备"
      }, {
        id: 2,
        name: "多账号归属同IP"
      }]
    };
  },
  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {};
    },
    modalTitle: function modalTitle() {
      return this.action == "pass" ? "批量通过" : this.action == "unpass" ? "批量驳回" : this.action == "add" ? "新建特征组" : this.action == 'edit' ? "编辑特征组" : '';
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    },
    statusText: function statusText() {
      return this.types[this.defaultIndex].name;
    }
  },
  created: function created() {
    // window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
    this.getList();
  },
  activated: function activated() {
    // window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
  },
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定启用/禁用
    handleDelSubmit: function handleDelSubmit() {
      var _this = this;
      this.subLoading = true;
      BlacklistApi.batchForbid(this.editId).then(function () {
        _this.toast("操作成功", "success");
        _this.isConfirmVisible = false;
        _this.subLoading = false;
        _this.getList();
      }).catch(function () {
        _this.isConfirmVisible = false;
        _this.subLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this2.selectedRowKeys = [];
          var formValues = cloneDeep(values);
          if (formValues.date && formValues.date.length > 0) {
            formValues.begin = formValues.date[0].format('YYYY-MM-DD');
            formValues.end = formValues.date[1].format('YYYY-MM-DD');
          }
          delete formValues.date;
          _this2.loading = true;
          var params = _objectSpread({
            page: _this2.page,
            size: _this2.size,
            type: _this2.status
          }, formValues);
          UserequipmentApi.list(_objectSpread({}, params)).then(function (res) {
            _this2.loading = false;
            _this2.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this2.total = (res === null || res === void 0 ? void 0 : res.total) || _this2.total;
          }).catch(function () {
            _this2.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    defineTipsDesc: function defineTipsDesc(action) {
      if (action == BATCH_UNPASS) {
        return '是否<span class="color-red">确认封禁</span>账号.';
      }
      return "";
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "deactive") {
        this.tipsDesc = this.defineTipsDesc(BATCH_UNPASS);
        this.editId = [{
          uid: record.uid,
          deviceid: record.deviceid,
          ipaddress: record.ipaddress
        }];
        this.isConfirmVisible = true;
      } else {
        return;
      }
    },
    tabClick: function tabClick(e, index) {
      // this.createForm.resetFields();
      this.defaultIndex = index;
      // window.GLOBAL.vbus.$emit("pageBtnDisabled", [
      //   this.status == 1 ? BATCH_PASS : BATCH_UNPASS,
      // ]);
      this.page = 1;
      this.size = 20;
      this.handleSearch();
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      var _this3 = this;
      // console.log(action, ADD)
      if (action == BATCH_UNPASS) {
        this.tipsDesc = this.defineTipsDesc(BATCH_UNPASS);
        var ids = this.getAllCheckedKey("uid");
        if (ids.length == 0) {
          this.toast("请先选择账号", "error");
          return;
        }
        this.editId = [];
        this.dataSource.forEach(function (it) {
          if (ids.includes(it.uid)) {
            _this3.editId.push({
              uid: it.uid,
              deviceid: it.deviceid,
              ipaddress: it.ipaddress
            });
          }
        });
        this.isConfirmVisible = true;
      } else if (action == OTHER) {
        this.handleExport();
      }
    },
    handleExport: function handleExport() {
      var _this4 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this4.selectedRowKeys = [];
          var formValues = cloneDeep(values);
          if (formValues.date && formValues.date.length > 0) {
            formValues.begin = formValues.date[0].format('YYYY-MM-DD HH:mm:ss');
            formValues.end = formValues.date[1].format('YYYY-MM-DD HH:mm:ss');
          }
          delete formValues.date;
          var params = _objectSpread({
            type: _this4.status
          }, formValues);
          UserequipmentApi.export(_objectSpread({}, params));
        } else {
          console.log(err);
        }
      });
    } /*******表格复选框end********/
  }
};